import React from 'react';

const App = (props) => {
  return (
    <div className="App" style={{display: "grid"}}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1214.39 20" preserveAspectRatio="xMidYMid meet" width="100%">
        <g class={props.class}>
          <rect class="bottom" width="1214.39" height="20"/>
          <path class="top" d="M0,14.23c19,0,19-8.46,38-8.46s19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46c18.79,0,19,8.26,37.38,8.45,18.38-.19,18.6-8.45,37.39-8.45,19,0,19,8.46,38,8.46s19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46,19-8.46,38-8.46,19,8.46,38,8.46V0H0Z"/>
        </g>
      </svg>
    </div>
  );
}
export default App;